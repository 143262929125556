.settingsForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  padding: 2em 0;

  section {
    min-width: 310px;

    header {
      h3 {
        display: inline-flex;
        align-items: center;
        gap: 0.3em;

        a {
          display: inline-flex;
        }
      }
    }
  }

  .logoForm {
    img {
      max-width: 310px;
    }
  }

  .socialLinks {
    main {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }
}
