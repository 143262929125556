.homePage {
  padding-bottom: 2em;

  > section h1 {
    font-size: 2em;
    border-bottom: 2px solid #7650a1;
    margin: 0;
  }

  > section {
    $gap: 1em;
    display: inline-flex;
    flex-direction: column;
    gap: $gap;

    &:not(:first-child) {
      margin-top: $gap;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      display: flex;
      flex-wrap: wrap;
      column-gap: $gap;

      li {
        display: flex;
        flex-basis: calc(1280px / 4 - $gap + $gap / 4);
        flex-grow: 1;

        h2 {
          font-size: 120%;
        }

        &:hover h2 {
          color: #c195f3;
          small {
            text-decoration: underline;
          }
        }

        article {
          max-width: 100%;
        }

        img {
          aspect-ratio: 16/9;
        }
      }
    }
  }

  .featuredSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: initial;
    gap: 1em;

    > a {
      flex-basis: 870px;
      flex-grow: 14;
    }

    aside {
      display: flex;
      flex-direction: column;
      gap: 1em;
      justify-content: flex-end;

      flex-basis: 250px;
      flex-grow: 1;
    }

    ul {
      flex-basis: 250px;
      align-content: flex-start;
      column-gap: 1em;

      li {
        font-size: 0.4em;

        a {
          margin-top: 1rem;
        }

        &:first-child {
          a {
            margin-top: 0;
          }
        }
      }
    }
  }
}
