.commentList {
  background: rgb(245 245 245);
  padding: 1em;

  > * {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > em {
    display: flex;
    justify-content: center;
    font-size: 2em;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 0.5em;
      padding: 1em;
      margin-bottom: 1em;
      box-shadow: 0 0 5px #dfdfdf;

      &:last-child {
        margin-bottom: 0;
      }

      header {
        display: flex;
        align-items: center;
        gap: 1em;

        img {
          width: 3em;
        }

        div {
          display: flex;
          flex-direction: column;
        }

        small {
          font-weight: initial;
        }
      }
    }

    p {
      margin-bottom: 0;
      line-height: 1.5;
      margin-left: 4em;
      margin-top: 0;
    }
  }

  form {
    position: relative;

    fieldset {
      display: flex;
    }

    label {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    textarea {
      border-radius: 0.5em;
      border: 2px solid #cfcece;

      &::placeholder {
        font-size: 0.8em;
        // NOTE: Required to offset the vertical position of the placeholder, centering it
        line-height: 1.5em;
        vertical-align: middle;
      }
    }

    button {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: 0.7em;
      margin-right: 0.7em;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}
