.articleEditor {
  header {
    visibility: hidden;
    display: flex;
    position: sticky;
    top: 1em;
    column-gap: initial;
    z-index: 1;

    button {
      display: inline-flex;
      color: inherit;
      padding: 0.2em;
      border-radius: 0;
      border: 1px solid rgba(0, 0, 0, 0);
      background-color: initial;
      font-size: 1em;
    }

    .buttonGroup {
      display: inline-flex;
      align-items: flex-end;
      border-right: 1px solid #eee;
      background: white;
      margin: 0.1em;
      padding: 0.3em;

      border: 1px solid rgb(184, 184, 184);
      border-radius: 0.2em;

      button {
        color: #c7c7c7;
      }

      button.small {
        font-size: 0.85em;
      }

      button.active {
        color: inherit;
      }

      .altButton {
        color: inherit;
      }
    }
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:active {
    header {
      visibility: visible;
    }
  }

  div[contenteditable="true"] {
    padding: 0.5em;
    margin: -0.5em;
  }

  .imageWrapper {
    > div {
      position: relative;
    }

    img {
      display: block;
      max-width: 100%;
      width: 100%;
      max-height: 20em;
      box-shadow: none;
    }

    button {
      display: none;
      position: absolute;
      top: 0.5em;
      left: 0.5em;
      background-color: white;

      &.small {
        padding: 0.3em;
      }
    }

    &.selected {
      img {
        box-shadow: 0 0 0 3px #b4d5ff;
      }

      button {
        display: inline-flex;
      }
    }
  }

  img {
    max-width: 100%;
    width: 100%;
  }
}
