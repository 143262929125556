.articleForm {
  &.preview {
    border-bottom: none;
  }

  &.overlayMode {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      background: rgba(0, 0, 0, 0.3);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8155637254901961) 0%,
        rgba(0, 0, 0, 0.03125) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    footer {
      position: absolute;
      bottom: 0;
      color: white;
      padding: 3%;
      font-size: min(2em, 7vw);
      z-index: 2;
    }
  }

  header,
  footer {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0.1em;
      margin-bottom: 0.1em;

      small {
        display: flex;
        opacity: 1 !important;
        align-items: center;
      }
    }

    h2 {
      font-size: min(1.5em, 8vw);
    }
  }

  main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.8em;
  }

  article {
    flex-grow: 1;
    position: relative;
    // NOTE: 95% on large devices, 100vw on mobile
    // max-width: min(95%, 100vw);
    flex-basis: 94%;
  }

  input,
  textarea {
    border: 0;
    vertical-align: middle;
    overflow-y: hidden;
    background: initial;
    color: inherit;
  }

  header,
  footer {
    textarea {
      font-family: "Intro";
      padding: 0;
    }
  }

  aside {
    flex-basis: 1.5em;
    flex-grow: 1;

    > div {
      position: sticky;
      // NOTE: Must add a gap to prevent overlapping with sticky navbar
      top: 5em;
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
    }

    img {
      max-width: 1.5em;
    }

    a {
      display: inline-flex;
      transition: rotate 0.1s;
    }

    a:hover,
    a:focus,
    a:active {
      rotate: 5deg;

      transition: rotate 0.1s;
    }
  }

  .tagSection {
    display: flex;
    flex-wrap: wrap;

    font-size: 1.5em;
    margin-left: auto;
    margin-bottom: 0.5em;

    gap: 0.1em;

    label {
      white-space: nowrap;

      > div {
        display: inline-flex !important;
      }
    }

    input {
      padding: 0;
      outline: none;

      &::placeholder {
        opacity: 0.5;
      }
    }
  }
}
