h1 small .react-datepicker-wrapper {
  width: auto;

  > * {
    display: inline-flex;
  }

  input {
    padding: 0;
  }
}

.react-datepicker {
  button {
    background-color: rgba($color: #000000, $alpha: 0) !important;
  }
}
