.socialLinkForm {
  fieldset {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    align-items: center;
    transition: padding 0.1s, box-shadow 0.1s;
  }

  &.edit,
  &.create {
    fieldset {
      flex-direction: column;
      box-shadow: 0 0 11px #ccc;
      padding: 1em;
      border-radius: 0.2em;
      align-items: initial;

      background: white;
    }
  }

  a {
    display: inline-flex;
  }

  label {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }

  .socialLink {
    margin: auto;
    align-self: center;
    justify-self: center;
    width: 1.5em;
  }

  &.edit,
  &.create {
    .socialLink {
      width: 2em;
      height: 2em;
    }
  }

  aside {
    display: flex;
  }

  .buttonAlt {
    display: inline-flex;
    color: initial;
    background-color: initial;
    padding: 0.1em;
  }

  .delete {
    color: red;
    margin-left: auto;
  }
}
