.landingPage {
  z-index: 4;
  margin-top: -5rem;
  text-align: center;

  // 3D box effect:
  // width: 95vw;
  // margin: auto;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: center;

    // 3D box effect:
    // box-shadow: 0px 160px 20px -3.5em #0000004f;
    // // NOTE: To put the shadow under the ::after
    // z-index: -1;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0);
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.03125) 45%,
        rgba(0, 0, 0, 0.8155637254901961) 100%
      );
    }

    &::after {
      content: attr(data-slogan);
      position: absolute;
      font-size: 3em;
      // color: #f6eef6;
      color: white;
      font-family: "Takota";
      // text-shadow: 0px 0px 10px black;
      text-shadow: 0 0 10px #0ea000;

      // NOTE: To put the shadow under the ::after
      z-index: 1;
    }

    button {
      position: absolute;

      color: white !important;
      font-size: 2em !important;
      bottom: 1em;
      opacity: 0.7;
    }
  }

  // 3D box effect:
  // &::after {
  //   content: "";
  //   // background: rgb(72 38 108);
  //   color: lightgrey;
  //   font-size: 4em;
  //   display: flex;
  //   justify-content: center;
  //   font-weight: bold;

  //   // border-top: 4vw solid rgb(43, 26, 60);
  //   // border-top: 4vw solid rgb(25, 20, 31);
  //   border-top: 4vw solid #0d0c0e;

  //   border-left: 5vw solid transparent;
  //   border-right: 5vw solid transparent;
  // }
}
