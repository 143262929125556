.articlePage {
  padding-bottom: 2em;

  > section > h2 {
    border-bottom: 0.1em solid #7650a1;
    margin-bottom: 0;
  }

  > .articleSection {
    $gap: 1em;
    display: inline-flex;
    flex-direction: column;
    gap: $gap;
    margin-top: $gap;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      display: flex;
      flex-wrap: wrap;
      gap: $gap;

      li {
        display: flex;
        flex-basis: calc(1280px / 4 - $gap + $gap / 4);
        flex-grow: 1;

        h2 {
          font-size: 120%;
        }

        &:hover h2 {
          color: #c195f3;
          small {
            text-decoration: underline;
          }
        }

        article {
          max-width: 100%;
        }

        img {
          aspect-ratio: 16/9;
        }
      }
    }
  }
}
