.fileInput {
  flex-wrap: wrap;
  gap: 1em;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
