.ProseMirror {
  // display: flex;
  // flex-direction: column;

  // NOTE: Eliminates the empty space at bottom of article
  img {
    display: block;
  }
}

.ProseMirror:focus-within {
  .ProseMirror-selectednode {
    outline: 2px solid;
  }
}
